.search-line{
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 62px;
    border: 1px solid #000;
	@include _1920{
        grid-template-columns: 1fr fluid_1280(62);
	}
    @include _992{
        grid-template-columns: 1fr 35px;
    }
    label{
        display: flex;
        align-items: center;
        width: 100%;
        input{
            border: none;
            width: 100%;
            padding-left: 29px;
            margin-bottom: 3px;
            font-size: 21px;
            height: 26.25px;
            @include _1920{
                padding-left: fluid_1280(29);
                margin-bottom: fluid_1280(3);
                font-size: fluid_1280(21);
                height: fluid_1280(26.25);
            }
            &::placeholder{
                font-size: 21px;
                height: 26.25px;
                @include _1920{
                    font-size: fluid_1280(21);
                    height: fluid_1280(26.25);
                }

            }
        }
    }
    input{
        @include _992{
            border: none;
            padding-left: 16px;
            margin-bottom: 1px;
            font-size: 12px;
            line-height: 125%;
            height: 34px;
        }
        &::placeholder{
            @include _992{
                font-size: 12px;
                line-height: 125%;
            }

        }
    }
    button{
        background-color: #000;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-left: 1px solid #000;
        transition: all .3s ease;

        border-radius: 0px;

        @include _992{
            border-radius: 0px;
        }
        &:hover{
            background-color: transparent;
            svg{
                stroke: #000;
            }
        }
        svg{
            width: 22px;
            height: 22px;
            fill: transparent;
            stroke: #fff;
            margin-bottom: 1px;
            transition: all .3s ease;
            @include _1920{
                width: fluid_1280(22);
                height: fluid_1280(22);
            }
            @include _992{
                width: 14px;
                height: 14px;
            }
        }
    }
}