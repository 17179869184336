.theme-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    svg {
        width: 36px;
        height: 28px;
        fill: #14A356;
        margin: 0 0 25px;
    }

    span {
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
        color: #333333;
        @include _992{
            font-size: 24px;
            line-height: 28px;
        }
    }
}


// Basic Modal Styles
.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    overflow-y: scroll;
}

.modal__container {
    background-color: #fff;
    padding: 50px 60px;
    max-height: 95vh;
    overflow: auto;
    max-width: 600px;
    border-radius: 15px;
    &.modal-stock{
        max-width: 1785px;
        width: 100%;
        padding: 44px 80px 59px 59px;
        border-radius: 14px;
    }
}

.modal__header {}

.modal__content{
    .theme-policy{
        text-align: center;
    }
}

.modal__title {
    margin-bottom: 30px;
    @include _1920{
        margin-bottom: fluid_1280(30);
    }
    @include _992{
        margin-bottom: 22px;
    }
    &--center{
        text-align: center;
    }
    p{
        font-family: 'Golos';
        font-weight: 600;
        font-size: 39px;
        line-height: 120%;
        @include _1920{
            font-size: fluid_1280(39);
        }
        @include _992{
            font-size: 18px;
        }
        span{
            font-family: 'Golos';
            font-weight: 600;
            font-size: 39px;
            line-height: 120%;
            color: #0F20E5;
            padding: 0;
            @include _1920{
                font-size: fluid_1280(39);
            }
            @include _992{
                font-size: 18px;
            }
        }
    }
    span{
        font-family: 'Golos';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;
        padding: 0 45px;
        text-align: center;
        @include _1920{
            font-size: fluid_1280(16);
            padding: 0 fluid_1280(45);
        }
        @include _992{
            font-size: 12px;
            padding: 22px;
        }
    }
}

.modal__map{
    display: grid;
    grid-template-columns: 1fr 550px;
    column-gap: 32px;
    &__left{
        filter: drop-shadow(1.48px 1.48px 8.91px rgba(0, 0, 0, 0.16));
        border-radius: 14px;
        max-height: 600px;
        height: 78vh;
        background-color: #fdfdfd;
    }
    &__right{
        box-shadow: 1.46px 1.46px 8.77px rgba(0, 0, 0, 0.16);
        border-radius: 14px;
        padding: 20px 10px;
        &__wrapper{
            max-height: 615px;
            overflow-x: auto;
            padding: 0 61px 0 38px;
            &::-webkit-scrollbar {
                width: 2px;               /* ширина всей полосы прокрутки */
            }
            &::-webkit-scrollbar-thumb {
                background-color: #E64569;    /* цвет бегунка */
                border-radius: 20px;       /* округлось бегунка */
                border: 3px solid #E64569;  /* отступ вокруг бегунка */
            }
        }
    }
    &__address{
        display: flex;
        flex-direction: column;
        margin-bottom: 34px;
        &__title{
            margin-bottom: 35px;
            cursor: pointer;
            &:hover{
                span{
                    color: #0F20E5;
                }
            }
            span{
                font-family: 'Golos';
                font-weight: 500;
                font-size: 26px;
                line-height: 125%;
            }
        }
        &__item{
            display: flex;
            align-items: start;
            &__val{
                display: flex;
                flex-direction: column;
                span{
                    &:not(:last-child){
                        margin-bottom: 10px;
                    }
                }
            }
            svg{
                height: 32px;
                width: 24px;
                fill: #E64569;
                margin-right: 15px;
            }
            span{
                font-family: 'PT Sans';
                font-size: 21px;
                line-height: 140%;
            }
            &:not(:last-child){
                margin-bottom: 13px;
            }
        }
        &:not(:last-child){
            border-bottom: 1px solid #CBCBCB;
            padding: 34px 0;
        }
    }
}

.modal__subtitle {
    font-weight: normal;
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: $text-clr;
    margin: 12px 0 0;
    @include _992{
        font-size: 14px;
        line-height: 18px;
        margin: 6px 0 0;
        br{display: none;}
    }
}

.modal__close {
    position: absolute;
    cursor: pointer;
    border: none;
    font-size: 30px;
    line-height: 30px;
    background-color: transparent;
    top: 0;
    right: 0;
    padding: 8px 18px;
    color: $text-clr;
    transition: color $tr-time;

    &:hover {
        color: $main-clr-hover;
    }
    @include _992{
        padding: 0 10px;
    }
}

@keyframes mmfadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes mmslideIn {
    from {
        // transform: translateY(15%);
    }

    to {
        // transform: translateY(0);
    }
}

@keyframes mmslideOut {
    from {
        // transform: translateY(0);
    }

    to {
        // transform: translateY(-10%);
    }
}

.micromodal-slide {
    display: none;
}

.micromodal-slide.is-open {
    display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
    will-change: transform;
}