
.title-underline{
    border-bottom: 2px solid $main-clr;
    padding: 0 0 18px;
    @include _992{
        padding: 0 0 10px;
    }
}
.bordered-text{
    padding: 28px 128px 28px 34px;
    background: #FAFBF9;
    border: 1px solid #E3E3E3;
    border-radius: 8px;
    max-width: 960px;
    @include _1920{
        padding: fluid_1280(28) fluid_1280(128) fluid_1280(28) fluid_1280(34);
        border-radius: fluid_1280(8);
        max-width: fluid_1280(960);
    }
    @include _992{
        padding: 10px;
        border-radius: 5px;
        max-width: none;
    }
    span{
        font-family: 'Golos';
        font-size: 16px;
        line-height: 145%;
        @include _1920{
            font-size: fluid_1280(16);
        }
        @include _992{
            font-size: 13px;
        }
    }
}

.content-text{
    h1{
        font-family: 'Golos';
        font-weight: 400;
        font-size: 48px;
        line-height: 57.6px;
        color: $text-clr;
        margin: 0 0 17px; 
        @include _1920{
            font-size: fluid_1280(48);
            line-height: fluid_1280(57.6);
            margin: 0 0 fluid_1280(17);
        }
        @include _992{
            font-size: 21px;
            line-height: 120%;
            margin: 0 0 8px;
        }
    }
    h2{
        font-family: 'Golos';
        font-weight: 600;
        font-size: 39px;
        line-height: 120%;
        color: #000;
        margin: 0 0 25px;
        @include _1920{
            font-size: fluid_1280(39);
            margin: 0 0 fluid_1280(25);
        }
        @include _992{
            font-size: 20px;
            margin: 0 0 9px;
        }
        &:not(:first-child){
            margin: 38px 0 14px;
            @include _1920{
                margin: fluid_1280(38) 0 fluid_1280(14);
            }
        }
    }
    h3{
        font-family: 'Golos';
        font-weight: 600;
        font-size: 36px;
        line-height: 125%;
        margin: 0 0 18px;
        @include _1920{
            font-size: fluid_1280(36);
            margin: 0 0 fluid_1280(18);
        }
        @include _992{
            font-size: 16px;
            margin: 0 0 10px;
        }
    }
    h4{
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: $text-clr;
        margin: 35px 0 10px;
        @include _992{
            font-size: 18px;
            line-height: 22px;
            margin: 20px 0 10px;
        }
    }
    p, a, li{
        font-family: 'PT Sans';
        font-size: 21px;
        line-height: 26.25px;
        // color: $text-clr;
        color: #515151;
        @include _1920{
            font-size: fluid_1280(21);
            line-height: fluid_1280(26.25);
        }
        @include _992{
            font-size: 12px;
            line-height: 125%;
        }
    }
    p{
        margin: 0 0 25px;
        @include _1920{
            margin: 0 0 fluid_1280(25);
        }
    }
    a{
        color: $main-clr;
        transition: color $tr-time;
        border-bottom: 1px solid $main-clr;
        &:hover{
            color: $text-clr;
            border-bottom: 1px solid $text-clr;
        }
        &:visited{
            color: #AA85D7;
            border-bottom: 1px solid #AA85D7;
        }
    }
    img{
        max-width: 100%;
        width: 100%;
        margin: 35px 0;
        @include _992{
            margin: 20px 0;
        }
    }

    ol, ul{
        padding-left: 37px;
        margin: 8px 0;
        @include _1920{
            padding-left: fluid_1280(37);
        }
        @include _992{
            padding-left: 20px;
        }
        li{
            padding-left: 5px;
            &:not(:last-child){
                margin: 0 0 18px;
            }
        }
    }

    ol{
        li{
            list-style-type: decimal;
        }
    }
    ul{
        li{
            list-style-type: none;
            position: relative;
            &::before{
                content: '';
                display: block;
                width: 5px;
                height: 5px;
                background-color: $main-clr;
                border-radius: 50%;
                position: absolute;
                top: calc(50% - 4px);
                left: -10px;
            }
        }
    }
    .table-wrap{
        max-width: 100%;
        overflow: auto;
    }
    table{
        margin: 35px 0 0;
        border: none;
        width: 100%;
        border-collapse: collapse;
        @include _992{
            margin: 20px 0 0;
        }
        > caption{
            font-weight: 700;
            font-size: 32px;
            line-height: 36px;
            color: $text-clr;
            border-bottom: 2px solid $main-clr;
            text-align: left;
            padding: 0 0 18px;
            @include _992{
                font-size: 24px;
                line-height: 28px; 
            }
        }

        tbody{
            border: none;
        }

        td{
            font-family: 'PT Sans';
            font-size: 21px;
            line-height: 140%;
            text-align: left;
            border: 1px solid #574848;
            padding: 23px 0 20px 30px;
            @include _1920{
                font-size: fluid_1280(21);
                padding: fluid_1280(23) 0 fluid_1280(20) fluid_1280(30);
            }
            @include _992{
                font-size: 13px;
                padding: 10px 8px 12px;
            }
        }

        th{
            font-family: 'Golos';
            font-weight: 600;
            font-size: 30px;
            line-height: 120%;
            background-color: #000;
            text-align: center;
            color: #fff;
            padding: 17px 0 22px;
            @include _1920{
                font-size: fluid_1280(30);
                padding: fluid_1280(17) 0 fluid_1280(22);
            }
            @include _992{
                font-size: 14px;
                padding: 12px 0 14px;
            }
        }
    }
}