@import 'vendors/swiper.min';


@import 'base/app';
@import 'components/category-card';
@import 'components/countdown';
@import 'components/product-card';
@import 'components/product-slider';
@import 'components/content/seo';
@import 'components/content/text';

.index-menu{
    margin-top: 51px;
    @include _1920{
        margin-top: fluid_1280(51);
    }
    &__container{
        display: grid;
        grid-template-columns: 579px 1fr;
        column-gap: 33px;
        position: relative;
        @include _1920{
            grid-template-columns: fluid_1280(579) 1fr;
            column-gap: fluid_1280(33);
        }
        @include _992{
            grid-template-columns: 1fr;
        }
    }
    &__block{
        display: grid;
        grid-template-columns: 579px 1fr;
        margin: 25px 0px;
        column-gap: 33px;
        position: relative;
        @include _1920{
            grid-template-columns: fluid_1280(579) 1fr;
            column-gap: fluid_1280(33);
        }
        @include _992{
            grid-template-columns: 1fr;
        }
    }
    &__menu-wrapper{
        max-height: 697px;
        display: flex;
        flex-direction: column;
        padding: 8px 33px;
        box-shadow: 1.5px 1.5px 9px 0px #00000029;
        border-radius: 15px;
        @include _1920{
            padding: fluid_1280(8) fluid_1280(33);
            border-radius: fluid_1280(15);
        }
        @include _992{
            display: none;
        }

    }
    &__item{
        display: flex;
        // height: 75px;
        align-items: center;
        // padding: 0 23px 0 27px;
        padding: 12px 27px;
        @include _1920{
            padding: 0 fluid_1280(23) 0 fluid_1280(27);
            height: fluid_1280(75);
        }
        a{
            display: flex;
            align-items: center;
            height: 90%;
            width: 100%;
            justify-content: space-between;
            span{
                font-family: 'Golos';
                font-size: 20px;
                line-height: 30px;
                font-weight: 500;
                transition: color .3s ease;
                @include _1920{
                    font-size: fluid_1280(24);
                    line-height: fluid_1280(30);
                }
            }
            svg{
                width: 10px;
                height: 10px;
                transform: rotate(-90deg);
                fill: transparent;
                stroke: #000;
                @include _1920{
                    width: fluid_1280(10);
                    height: fluid_1280(10);
                }
            }
        }
        &:hover{
            a{
                span{
                    color: #FF1493;
                }
            }
        }
        &:not(:last-child){
            border-bottom: 1px solid #D9D9D9;
        }
    }
    &__right{
        display: flex;
        flex-direction: column;
        &__banner{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 60px;
            @include _1920{
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: fluid_1280(60);
            }
            @include _992{
                grid-template-columns: 1fr 1fr 1fr;
                row-gap: 12px;
                margin-bottom: 30px;
            }
            img{
                // height: 316px;
                width: 100%;
                object-fit: cover;
                border-radius: 15px;
                @include _1920{
                    height: fluid_1280(316);
                    border-radius: fluid_1280(15);
                }
                @include _992{
                    height: calcFluid(134, 250, 320, 800);
                    width: 100%;
                }
            }
        }
        
        &__form{
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 15px;
            box-shadow: 1.5px 1.5px 9px 0px #00000029;
            padding: 30px 55px 51px 57px;
            @include _1920{
                padding: fluid_1280(30) fluid_1280(55) fluid_1280(51) fluid_1280(57);
                border-radius: fluid_1280(15);
            }
            @include _992{
                padding: 21px 16px 26px;
                border-radius: 5px;
            }
            &__title{
                text-align: center;
                margin-bottom: 15px;
                @include _1920{
                    margin-bottom: fluid_1280(15);
                }
                @include _992{
                    margin-bottom: 9px;
                    width: 60%;
                }
                span{
                    font-size: 36px;
                    line-height: 45px;
                    font-family: 'Golos';
                    font-weight: 700;
                    @include _1920{
                        font-size: fluid_1280(36);
                        line-height: fluid_1280(45);
                    }
                    @include _992{
                        font-family: 'Golos';
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 125%;
                    }
                }
            }
            &__more{
                text-align: center;
                margin-bottom: 31px;
                @include _1920{
                    margin-bottom: fluid_1280(31);
                }
                @include _992{
                    margin-bottom: 16px;
                }
            }
            &__btns{
                display: grid;
                grid-template-columns: 1fr 72px;
                column-gap: 15px;
                height: 69px;
                width: 100%;
                @include _1920{
                    grid-template-columns: 1fr fluid_1280(72);
                    column-gap: fluid_1280(15);
                    height: fluid_1280(69);
                }
                @include _992{
                    grid-template-columns: 1fr 57px;
                    column-gap: 8px;
                    height: 34px;
                }
                &__vk{
                }
            }
        }
        &__text{
            padding: 38px 0px 9px 15px;
            @include _1920{
                padding: fluid_1280(38) 0px fluid_1280(9) fluid_1280(15);
            }
            @include _992{
                padding: 0;
                margin-bottom: 30px;
            }
        }
        &__subscribe{
            background-color: #000;
            box-shadow: 1.54px 1.5px 9px 0px #00000029;
            border-radius: 15px;
            display: grid;
            grid-template-columns: 1fr 347px 183px;
            column-gap: 15px;
            padding: 28px 43px 35px 52px;
            @include _1920{
                grid-template-columns: 1fr fluid_1280(347) fluid_1280(183);
                column-gap: fluid_1280(15);
                padding: fluid_1280(28) fluid_1280(43) fluid_1280(35) fluid_1280(52);
                border-radius: fluid_1280(15);
            }
            @include _992{
                grid-template-columns: 1fr;
                padding: 21px 20px 26px;
                border-radius: 0px;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    display: block;
                    z-index: -1;
                    width: calc(100% + 30px);
                    left: -15px;
                    height: 100%;
                    background-color: #000;
                }
            }
            &__title{
                font-family: 'Golos';
                font-size: 36px;
                line-height: 43px;
                font-weight: 600;
                color: #fff;
                @include _1920{
                    font-size: fluid_1280(36);
                    line-height: fluid_1280(43);
                }
                @include _992{
                    font-size: 18px;
                    line-height: 125%;
                    text-align: center;
                    margin-bottom: 17px;
                }
            }
            &__btn{
                border: 1px solid transparent;
                background-color: #FF1493;
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 69px;
                margin-top: 8px;
                @include _1920{
                    border-radius: fluid_1280(12);
                    height: fluid_1280(69);
                    margin-top: fluid_1280(8);
                }
                @include _992{
                    border-radius: 5px;
                    height: 34px;
                    margin-top: 0;
                }
                span{
                    color: #fff;
                    font-size: 24px;
                    line-height: 30px;
                    font-family: 'Golos';
                    @include _1920{
                        font-size: fluid_1280(24);
                        line-height: fluid_1280(30);
                    }
                    @include _992{
                        font-size: 14px;
                        line-height: 125%;
                    }
                }
            }
            a{
                height: 69px;
                margin-top: 8px;
                @include _1920{
                    height: fluid_1280(69);
                    margin-top: fluid_1280(8);
                }
                @include _992{
                    height: 34px;
                    margin-top: 8px;
                }
            }
        }
    }
    &__category{
        z-index: 5;
        position: absolute;
        right: 0px;
        box-shadow: 1.5px 1.5px 9px rgba(0, 0, 0, 0.16);
        border-radius: 15px;
        margin-left: 10px;
        padding: 50px 36px;
        width: 1205px;
        display: flex;
        justify-content: space-between;
        height: min-content;
        display: none;
        background-color: #fff;
        @include _1920{
            border-radius: 0 0 fluid_1280(15) fluid_1280(15);
            margin-left: fluid_1280(10);
            padding: fluid_1280(50) fluid_1280(36);
            width: fluid_1280(1205);
        }
        &.opened{
            display: flex;
        }
        &__left{
            display: flex;
            flex-direction: column;
            height: fit-content;
            min-width: 320px;
        }
        &__right{
            display: flex;
            img{
                height: 100%;
                width: 100%;
                // height: 337px;
                // width: 337px;
                object-fit: cover;
                // @include _1920{
                    // height: fluid_1280(337);
                    // width: fluid_1280(337);
                // }
            }
        }
        &__title{
            margin-left: 30px;
            margin-bottom: 14px;
            @include _1920{
                margin-left: fluid_1280(30);
                margin-bottom: fluid_1280(34);
            }
            span{
                font-family: 'Golos';
                font-weight: 600;
                font-size: 30px;
                line-height: 120%;
                @include _1920{
                    font-size: fluid_1280(30);
                }
            }
        }
        &__item{
            padding: 5px 30px;
            border-radius: 30px;
            border: 1px solid transparent;
            transition: all .3s ease;
            @include _1920{
                padding: fluid_1280(11) fluid_1280(30);
                border-radius: fluid_1280(30);
            }
            &:hover{
                border-color: #FF1493;
                span{
                    color: #FF1493;
                }
            }
            &:not(:last-child){
                margin-bottom: 5px;
                @include _1920{
                    margin-bottom: fluid_1280(5);
                }
            }
            span{
                font-family: 'Golos';
                font-weight: 500;
                font-size: 19px;
                line-height: 125%;
                border: 1px solid transparent;
                @include _1920{
                    font-size: fluid_1280(24);
                }
            }
        }
    }
}
.index-product{
    margin-top: 75px;
    @include _1920{
        margin-top: fluid_1280(75);
    }
    @include _992{
        margin-top: 25px;
    }
    &__title{
        margin-bottom: 29px;
        @include _1920{
            margin-bottom: fluid_1280(29);
        }
        @include _992{
            margin-bottom: 12px;
        }
        span{
            font-family: 'Golos';
            font-weight: 600;
            font-size: 39px;
            line-height: 46.8px;
            @include _1920{
                font-size: fluid_1280(39);
                line-height: fluid_1280(46.8);
            }
            @include _992{
                font-size: 20px;
                line-height: 125%;
            }
        }
    }
    &__list{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 33px;
        @include _1920{
            column-gap: fluid_1280(33);
        }
        // .product-card{
            // &__name{
                // font-size: 20px;
                // line-height: 25px;
                // height: 100px;
                // @include _1920{
                //     font-size: fluid_1280(20);
                //     line-height: fluid_1280(25);
                //     height: fluid_1280(100);
                // }
            // }
            // &__price{
                // margin: 16px 0 0;
                // &--current{
                    // font-weight: 500;
                    // font-size: 24px;
                    // @include _1920{
                    //     font-size: fluid_1280(24);
                    //     line-height: fluid_1280(30);
                    // }
                // }
                // &--old{
                //     font-size: 20px;
                //     line-height: 25px;
                //     @include _1920{
                //         font-size: fluid_1280(20);
                //         line-height: fluid_1280(25);
                //     }
                // }
            // }
        // }
    }
}
.index-partner{
    background-color: #F5F5F5;
    padding: 45px 0 25px;
    margin-top: 75px;
    @include _1920{
        padding: fluid_1280(45) 0 fluid_1280(25);
        margin-top: fluid_1280(75);
    }
    @include _992{
        padding: 22px 0 11px;
        margin-top: 30px;
    }
    &__list{
        display: flex;
        // justify-content: space-between;
        column-gap: calcFluid(17, 28, 320, 1920);
        flex-wrap: wrap;
    }
    &__item{
        border-radius: 15px;
        box-shadow: 0px 1.5px 3px 0px #0000001A;
        padding: 21px 30px;
        background-color: #fff;
        margin-bottom: 20px;
        @include _1920{
            border-radius: fluid_1280(15);
            margin-bottom: fluid_1280(20);
            padding: fluid_1280(21) fluid_1280(30);
        }
        @include _992{
            background-color: transparent;
            margin-bottom: 19px;
            padding: 0px;
            border-radius: none;
            box-shadow: none;
        }
        img{
            height: 60px;
            object-fit: cover;
            aspect-ratio: auto;
            @include _1920{
                height: fluid_1280(60);
            }
            @include _992{
                height: 25px;
            }
        }

    }
}

.index-advantages{
    margin-top: 75px;
    @include _1920{
        margin-top: fluid_1280(75);
    }
    @include _992{
        margin-top: 15px;
    }
    &__list{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 33px;
        @include _1920{
            column-gap: fluid_1280(33);
        }
        @include _992{
            grid-template-columns: 1fr;
            row-gap: 14px;
        }
    }
    &__item{
        border-radius: 15px;
        box-shadow: 0px 1.5px 3px 1.5px #5E5E5E26;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 256px;
        padding: 46px 44px;
        @include _1920{
            border-radius: fluid_1280(15);
            height: fluid_1280(256);
            padding: fluid_1280(46) fluid_1280(44);
        }
        @include _992{
            border-radius: 5px;
            height: 131px;
            padding: 0;
            justify-content: center;
        }
        &__img{
            @include _992{
                margin-bottom: 10px;
            }
            svg{
                fill: #E64569;
                &.house{
                    height: 103px;
                    width: 103px;
                    @include _1920{
                        height: fluid_1280(103);
                        width: fluid_1280(103);
                    }
                    @include _992{
                        height: 50px;
                        width: 50px;
                    }
                }
                &.car{
                    height: 107px;
                    width: 107px;
                    @include _1920{
                        height: fluid_1280(107);
                        width: fluid_1280(107);
                    }
                    @include _992{
                        height: 64px;
                        width: 64px;
                    }
                }
                &.box{
                    height: 107px;
                    width: 107px;
                    @include _1920{
                        height: fluid_1280(107);
                        width: fluid_1280(107);
                    }
                    @include _992{
                        height: 50px;
                        width: 50px;
                    }
                }
            }
        }
        &__title{
            text-align: center;
            span{
                font-family: 'Golos';
                font-size: 30px;
                line-height: 120%;
                font-weight: 600;
                @include _1920{
                    font-size: fluid_1280(30);
                }
                @include _992{
                    font-size: 17px;
                }
            }
        }
    }
}
.index-seo-1{
    margin-top: 99px;
    overflow-x: clip;
    @include _1920{
        margin-top: fluid_1280(99);
    }
    @include _992{
        margin-top: 30px;
        overflow: hidden;
    }
    &__container{
        display: grid;
        grid-template-columns: 885px 1fr;
        column-gap: 60px;
        position: relative;
        // overflow-x: hidden;
        // overflow-y: clip;
        @include _1920{
            grid-template-columns: fluid_1280(885) 1fr;
            column-gap: fluid_1280(60);
        }
        @include _992{
            grid-template-columns: 1fr;
            row-gap: 20px;
        }
    }
    &__img{

        img{
            height: 525px;
            width: 885px;
            object-fit: cover;
            @include _1920{
                height: fluid_1280(525);
                width: fluid_1280(885);
            }
            @include _992{
                height: calcFluid(165, 240, 320, 800    );
                width: 100%;
                border-radius: 5px;
            }
        }
    }
    &__content{
        padding: 55px 0;
        @include _1920{
            padding: fluid_1280(55);
        }
        @include _992{
            padding: 0;
        }
    }
    &__buttons{
        display: grid;
        grid-template-columns: 226px 72px;
        column-gap: 16px;
        margin-top: 51px;
        @include _1920{
            grid-template-columns: fluid_1280(226) fluid_1280(72);
            column-gap: fluid_1280(16);
            margin-top: fluid_1280(51);
        }
        @include _992{
            grid-template-columns: 1fr 64px;
            column-gap: 8px;
            margin-top: 24px;
        }
    }
    &__signup{
        height: 69px;
        @include _1920{
            height: fluid_1280(69);
        }
        @include _992{
            height: 34px;
        }
    }
    &__vk{
        height: 69px;
        @include _1920{
            height: fluid_1280(69);
        }
        @include _992{
            height: 34px;
        }
    }
    &__more{
        margin-top: 24px;
        @include _1920{
            margin-top: fluid_1280(24);
        }
        @include _992{
            margin-top: 10px;
            text-align: center;
        }
    }
    &__right-img{
        position: absolute;
        bottom: -78px;
        right: -57px;
        @include _1920{
            bottom: fluid_1280(-78);
            right: fluid_1280(-57);
        }
        @include _992{
            top: 70px;
            right: -50px;
        }
        img{
            width: 291px;
            height: 339px;
            border-radius: 15px;
            @include _1920{
                width: fluid_1280(291);
                height: fluid_1280(339);
                border-radius: fluid_1280(15);
            }
            @include _992{
                width: 126px;
                height: 145px;
                border-radius: 5px;
            }
        }
    }
}
.index-seo-2{
    margin-top: 60px;
    @include _1920{
        margin-top: fluid_1280(60);
    }
    @include _992{
        margin-top: 30px;
    }
    &__container{
        display: grid;
        grid-template-columns: 1fr 885px;
        column-gap: 60px;
        position: relative;
        @include _1920{
            grid-template-columns: 1fr fluid_1280(885);
            column-gap: fluid_1280(60);
        }
        @include _992{
            grid-template-columns: 1fr;
            row-gap: 18px;
            grid-auto-flow: dense;
        }
    }
    &__img{

        img{
            height: 525px;
            width: 885px;
            object-fit: cover;
            border-radius: 15px;
            @include _1920{
                height: fluid_1280(525);
                width: fluid_1280(885);
                border-radius: fluid_1280(15);
            }
            @include _992{
                height: calcFluid(165, 240, 320, 800);
                width: 100%;
                border-radius: 5px;
            }
        }
    }
    &__content{
        // padding: 55px 0;
    }
}
.index-seo-3{
    margin-top: 47px;
    @include _1920{
        margin-top: fluid_1280(47);
    }
    @include _992{
        margin-top: 12px;
    }
}