.header-mobile{
    display: flex;
    flex-direction: column;
    background: #fff;
    &__top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #000;
        height: 35px;
        width: 100%;
        padding: 0 15px;
        &__logo{
            img{
                height: 100%;
                width: 72px;
                object-fit: cover;
            }
        }
        &__phone{
            border-left: 1px solid #fff;
            margin: 7px 0 8px;
            padding: 0 5px 0 9px;
            svg{
                stroke: none;
                fill: #fff;
                width: 16px;
                aspect-ratio: 1/1;
            }
        }
    }
    &__bottom{
        position: relative;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        &__burger{

        }
        &__actions{
            a{
                position: relative;
                svg{
                    width: 17px;
                    aspect-ratio: 1/1;
                    &.stroke{
                        stroke: #000;
                        fill: transparent;
                    }
                    &.fill{
                        stroke: transparent;
                        fill: #000;
                    }
                }
                span{
                    width: 15px;
                    aspect-ratio: 1/1;
                    display: flex;
                    background-color: #e64569;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                    font-size: 11px;
                    line-height: 125%;
                    color: #fff;
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    &:empty{
                        display: none;
                    }
                }
                &:not(:last-child){
                    margin-right: 21px;
                }
            }
        }
    }
    &-search{
        svg{
            stroke: #000; 
            fill: #fff; 
            width: 17px; 
            height: 17px; 
            margin-right: 21px;
        }
        &-show{
            transition: .3s ease;
            height: fc1280(57, 37);
            z-index: 5;
            overflow: hidden;
            position: absolute;
            width: 100%;
            left: 0;
            top: 40px
        }
    }
}


.header-burger{
    position: relative;
    width: 20px;
    height: 16px;
    span{
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $text-clr;
        transition: $tr-time;
        transition-property: top, bottom, opacity, transform;
        &:nth-child(1){
            top: 0;
        }
        &:nth-child(2){
            top: 0;
            bottom: 0;
            margin: auto;
        }
        &:nth-child(3){
            bottom: 0;
        }
    }
    &.opened{
        span{
            &:nth-child(1){
                top: calc(50% - 1px);
                transform: rotate(45deg);
            }
            &:nth-child(2){
                opacity: 0;
            }
            &:nth-child(3){
                bottom: calc(50% - 1px);
                transform: rotate(-45deg);
            }
        }
    }
}

.mobile-menu{
    position: fixed;
    z-index: 20;
    top: -125%;
    width: 90%;
    margin: 0 5%;
    padding: 16px 0;
    background-color: #fff;
    // overflow: scroll;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    transition: all .3s ease-out;
    border-radius: 0 0 3px 3px;
    box-shadow: 0.32px 0.32px 1.92px rgba(0, 0, 0, 0.16);
    &.opened{
        top: auto;
        padding-top: 0px;
        // &.menu-debug{
        //     top: auto;
        // }
    }

    .mobile-actions{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        @include _576{
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .header-button{
        flex-direction: row;
        padding: 20px;
        
        &:not(:last-child) {
            margin: 0;
        }
        svg{
            margin: 0 12px 0 0;
        }
        @include _1200{
            &:not(:last-child){
                border-right: 1px solid #D0D0D0;
            }
        }
        @include _576{
            &:not(:last-child){
                border-right: none;
            }
            &:nth-child(odd){
                border-right: 1px solid #D0D0D0;
            }
            &:nth-child(1),
            &:nth-child(2){
                border-bottom: 1px solid #D0D0D0;
            }
        }
    }

    .mobile-nav{
        &__list{
            padding: 11px 15px 0;
            display: none;
            max-height: 50vh;
            overflow: scroll;
            &.active{
                display: block;
            }
            li{
                &:not(:last-child){
                    border-bottom: 1px solid #D9D9D9;
                }
                &.back{
                    border: none;
                    border-radius: 5px;
                    background-color: #f4f4f4;
                    width: fit-content;
                    p{
                        justify-content: start;
                    }
                    svg{
                        fill: #000;
                        width: 12px;
                        transform: none;
                        margin-right: 12px;
                    }

                }
                a{

                }
                p{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 9px 15px 8px 11px;
                }
                svg{
                    fill: transparent;
                    stroke: #000;
                    width: 8px;
                    aspect-ratio: 1/1;
                    transform: rotate(-90deg);
                    margin-right: 10px;
                }
                span{
                    font-family: 'Golos';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 125%;
                }
            }
        }
    }

    .mobile-main{
        // padding: 0 0 25px;
        // max-height: calc(90vh - 77px);
        // max-height: 500px;
        // overflow-y: auto;
    }
    .mobile-phone{
        display: flex;
        flex-direction: column;
        padding: 0 14px;
        margin-top: 28px;
        margin-bottom: 15px;
        a{
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            svg{
                width: 16px;
                height: 16px;
                fill: #000;
                margin-right: 2px;
            }
            span{
                font-family: 'Golos';
                font-size: 18px;
                line-height: 125%;
                opacity: 1;
            }
        }
        span{
            font-family: 'Golos';
            font-size: 12px;
            line-height: 125%;
            opacity: .8;
        }
    }
    .mobile-menu-btn{
        height: 34px;
        margin: 0 15px;
    }
    
    .mobile-lead{
        padding: 15px 15px 20px;
        background-color: #F8F8F8;
        .header-lead{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 20px;
            &__button{
                width: 100%;
                height: 45px;
            }
        }
        .lead-phone{
            margin-right: 0px;
            span{
                font-size: 13px;
            }
        }
    }

    .mobile-search{
        padding: 0 15px;

        .search-line{
            width: 100%;
        }
    }
}
.js-hide{
    height: 0px;
}