
.footer{
    background-color: #000;
    overflow: hidden;
    &__wrapper{
        display: grid;
        grid-template-columns: 380px 226px 225px 379px 1fr;
        column-gap: 80px;
        padding-top: 45px;
        @include _1920{
            grid-template-columns: fluid_1280(380) fluid_1280(226) fluid_1280(225) fluid_1280(379) 1fr;
            column-gap: fluid_1280(80);
            padding-top: fluid_1280(45);
        }
        @include _992{
            grid-template-columns: 1fr;
            padding-top: 28px;
            row-gap: 44px;
        }
    }
    &__col{
        display: flex;
        flex-direction: column;
        &__wrap{
            padding-top: 22px;
            @include _1920{
                padding-top: fluid_1280(22);
            }
            @include _992{
                padding-top: 0px;
            }

        }
        &.footer-logo{
            @include _992{
                flex-direction: row;
            }
        }
    }
    &__item{
        margin-bottom: 12px;
        @include _1920{
            margin-bottom: fluid_1280(12);
        }
        @include _992{
            margin-bottom: 20px;
        }
        &.more-margin{
            margin-bottom: 21px;
            @include _1920{
                margin-bottom: fluid_1280(21);
            }
            @include _992{
                margin-bottom: 17px;
            }
        }
        &:hover{
            span{
                color: #E64569;
            }
        }
    }
    &__logo{
        margin-bottom: 15px;
        @include _1920{
            margin-bottom: fluid_1280(15);
        }
        @include _992{
            margin-bottom: 0;
            margin-right: calcFluid(28, 40, 320 , 1000);
        }
        img{
            width: 273px;
            aspect-ratio: 273/141;
            @include _1920{
                width: fluid_1280(273);
            }
            @include _992{
                width: calcFluid(100, 150, 320, 800);
            }
        }
        &__text{
            font-family: 'PT Sans';
            font-size: 18px;
            line-height: 115%;
            color: #fff; 
            max-width: 280px;
            @include _1920{
                max-width: fluid_1280(280);
                font-size: fluid_1280(18);
            }
            @include _992{
                max-width: 50%;
                font-size: calcFluid(10, 12, 320, 800);
                line-height: calcFluid(13, 15, 320, 800);
                margin-top: 8px;
            }
        }
    }
    &__text{
        font-family: 'Golos';
        font-size: 21px;
        line-height: 125%;
        color: rgba(255, 255, 255, .6);
        @include _1920{
            font-size: fluid_1280(21);
        }
        @include _992{
            font-size: 14px;
        }
        &-bold{
            font-family: 'Golos';
            font-size: 21px;
            line-height: 125%;
            color: #fff;
            @include _1920{
                font-size: fluid_1280(21);
            }
            // @include _1920{
            //     font-size: 14px;
            // }
        }
    }
    &__phone{
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        @include _1920{
            margin-bottom: fluid_1280(30);
        }
        @include _992{
            margin-bottom: 20px;
        }
        a{
            font-family: 'Golos';
            font-weight: 600;
            font-size: 27px;
            line-height: 125%;
            color: #fff;
            margin-bottom: 3px;
            transition: all .3s ease;
            &:hover{
                color: #E64569;
            }
            @include _1920{
                font-size: fluid_1280(27);
                margin-bottom: fluid_1280(3);
            }
            @include _992{
                font-size: 26px;
                margin-bottom: 5px;
            }
        }
        span{
            font-family: 'Golos';
            font-weight: 400;
            font-size: 21px;
            line-height: 125%;
            @include _1920{
                font-size: fluid_1280(21);
            }
            @include _992{
                font-size: 14px;
            }
        }
    }
    &__vk{
        margin-bottom: 19px;
        @include _1920{
            margin-bottom: fluid_1280(19);
        }
        @include _992{
            margin-bottom: 10px;
        }
        &:hover{
            svg{
                fill:#E64569;
            }
        }
        svg{
            width: 40px;
            height: 40px;
            fill: #fff;
            margin-right: 2px;
            transition: all .3s ease;
            @include _1920{
                width: fluid_1280(40);
                height: fluid_1280(40);
                margin-right: fluid_1280(2);
            }
            @include _992{
                width: 15px;
                height: 15px;
            }
        }
        span{
            font-family: 'Golos';
            font-size: 21px;
            line-height: 125%;
            color: rgba(255, 255, 255, .8);
            @include _1920{
                font-size: fluid_1280(21);
            }
            @include _992{
                font-size: 14px;
            }
        }
    }
    &__address{
        margin-bottom: 21px;
        @include _1920{
            margin-bottom: fluid_1280(21);
        }
        @include _992{
            margin-bottom: 10px;
        }
        a{
            &:hover{
                span{
                    text-decoration: none;
                }
            }
        }
        span{
            font-family: 'Golos';
            font-size: 24px;
            line-height: 125%;
            color: #fff;
            text-decoration: underline;
            @include _1920{
                font-size: fluid_1280(24);
            }
            @include _992{
                font-size: 14px;
            }
        }
    }
    &__mail{
        @include _992{
            margin-bottom: 20px;
        }
        span{
            font-family: 'Golos';
            font-size: 21px;
            line-height: 125%;
            color: rgba(255, 255, 255, 0.8);
            &:hover{
                color: #E64569;
            }
            @include _1920{
                font-size: fluid_1280(21);
            }
            @include _992{
                font-size: 14px;
            }
        }
    }
}
.footer-bottom{
    display: grid;
    grid-template-columns: 446px 198px 317px 290px 273px;
    column-gap: 70px;
    align-items: end;
    margin-top: 25px;
    padding-bottom: 31px;
    position: relative;
    @include _1920{
        grid-template-columns: fluid_1280(446) fluid_1280(198) fluid_1280(317) fluid_1280(290) fluid_1280(273);
        margin-top: fluid_1280(25);
        column-gap: fluid_1280(70);
        padding-bottom: fluid_1280(31);
    }
    @include _992{
        grid-template-columns: 1fr;
        margin-top: 100px;
        row-gap: 11px;
        padding-bottom: 20px;
    }
    &::before{
        width: 200vw;
        height: 1px;
        display: block;
        background-color: #fff;
        z-index: 1;
        top: -25px;
        margin-left: -50vw;
        position: absolute;
        @include _992{
            content: '';
        }
    }
    &__item{
        display: flex;
        align-items: end;
        span{
            font-family: 'Golos';
            font-size: 19.5px;
            line-height: 125%;
            color: rgba(255, 255, 255, 0.6);
            @include _1920{
                font-size: fluid_1280(19.5);
            }
            @include _992{
                font-size: 10px;
            }
        }
        a{
            span{
                font-family: 'Golos';
                font-size: 19.5px;
                line-height: 125%;
                text-decoration: underline;
                color: rgba(255, 255, 255, 0.6);
                transition: color .3s ease;
                @include _1920{
                    font-size: fluid_1280(19.5);
                }
                @include _992{
                    font-size: 10px;
                }
            }
            svg{
                width: 185px;
                height: 32px;
                margin-left: -5px;
                margin-bottom: -3px;
                fill: rgb(255, 255, 255);
                transition: fill .3s ease;
                @include _1920{
                    width: fluid_1280(185);
                    height: fluid_1280(32);
                    margin-left: fluid_1280(-5);
                    margin-bottom: fluid_1280(-3);
                }
                @include _992{
                    width: 60px;
                    height: 11px;
                    margin-left: 0px;
                    margin-bottom: -2px;
                }
            }
            &:hover{
                span{
                    color: #E64569;
                }
                svg{
                    fill: #E64569;
                }
            }
        }
    }
    &__btn{
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid #fff;
        border-radius: 12px;
        height: 69px;
        width: 100%;
        cursor: pointer;
        transition: all .3s ease;
        @include _1920{
            border-radius: fluid_1280(12);
            height: fluid_1280(69);
        }
        @include _992{
            display: none;
            height: 41px;
            border-radius: 5px;
        }
        &.mob{
            display: none;
            @include _992{
                display: flex;
            }
        }
        span{
            font-family: 'Golos';
            font-size: 24px;
            line-height: 125%;
            color: #fff;
            text-align: center;
            transition: all .3s ease;
            @include _1920{
                font-size: fluid_1280(24);
            }
            @include _992{
                font-size: 16px;
            }
        }
        &:hover{
            border-color: #E64569;
            span{
                color: #E64569;
            }
        }
    }
}

.footer-ps{
    @include _576{
        margin: 20px 0 0;
    }
    &:hover{
        span{
            color: $main-clr;
        }
        svg{
            fill: $main-clr;
        }
    }
    span{
        font-size: 15px;
        line-height: 20px;
        color: #B6B6B6;
        transition: color $tr-time;
    }
    svg{
        width: 120px;
        height: 25px;
        fill: #B6B6B6;
        transition: fill $tr-time;
    }
}