.countdown{
    position: relative;
    opacity: 0;
    transition: opacity $tr-time;
    &.active{
        opacity: 1;
    }
    &-value{
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        color: $text-clr;
        @include _420{
            font-size: 20px;
            line-height: 30px;
        }
    }
    &-text{
        font-size: 15px;
        line-height: 18px;
        color: $text-clr;
        @include _420{
            font-size: 12px;
            line-height: 14px;
        }
    }
    &::before{
        content: '';
        display: block;
        width: 100%;
        height: 10px;
        background-color: $main-clr;
        position: absolute;
        z-index: -1;
        bottom: 2px;
    }
}