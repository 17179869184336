.container{
	max-width: 1803px;
	margin: 0 auto;
	@include _1920{
		max-width: fluid_1280(1803);
	}
	@include _992{
		max-width: calc(100% - 30px);
	}
}
.container-2{
	max-width: 1758px;
	margin: 0 auto;
	@include _1920{
		max-width: fluid_1280(1758);
	}
	@include _992{
		max-width: calc(100% - 30px);
	}
}

#page-container{
	padding: 0 0 45px;
	@include _1920{
		padding: 0 0 fluid_1280(45);
	}
	@include _992{
		padding: 0 0 30px;
	}
}

em{
	font-style: normal;
	color: $main-clr-hover;
}

.loading{
	// opacity: .4;
	filter: blur(3px);
	pointer-events: none;
	user-select: none;
}

.btn{
	cursor: pointer;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;

	&--icon{
		background-color: transparent;
		transition: all .3s ease;
		&:hover{
			svg{
				stroke: #000;
			}
		}
		svg{
			width: 33px;
			height: 33px;
			fill: #B6B6B6;
			transition: all .3s ease;
			@include _1920{
				width: fluid_1280(40);
				height: fluid_1280(40);
			}
			@include _992{
				width: 16px;
				height: 16px;
			}
		}
	}

	&--filled{
		transition: background-color $tr-time;
		background-color: #000;
		border: 1px solid #fff;
		transition: all .3s ease;
		border-radius: 12px;
		@include _1920{
			border-radius: fluid_1280(12);
		}
		@include _992{
			border-radius: 5px;
		}
		span{
			font-family: 'Golos';
			font-weight: 400;
			font-size: 24px;
			line-height: 30px;
			color: #fff;
			transition: all .3s ease;
			@include _1920{
				font-size: fluid_1280(24);
				line-height: fluid_1280(30);
			}
			@include _992{
				font-size: 14px;
				line-height: 125%;
			}
		}
		svg{
			fill: #fff;
			transition: all .3s ease;
			width: 30px;
			height: 30px;
			margin-bottom: -2px;
			@include _1920{
				width: fluid_1280(30);
				height: fluid_1280(30);
			}
			@include _992{
				width: 17px;
				height: 17px;
			}
		}
		
		&:hover{
			background-color: #fff;
			border-color: #000;
			svg{
				fill: #000;
			}
			span{
				color: #000;
			}
		}
	}

	&--reverse{
		background-color: #fff;
		border: 1.5px solid #000;
		transition: all .3s ease;
		border-radius: 12px;
		@include _1920{
			border-radius: fluid_1280(12);
		}
		@include _992{
			border-radius: 5px;
		}
		span{
			font-family: 'Golos';
			font-weight: 400;
			font-size: 24px;
			line-height: 30px;
			color: #000;
			transition: color .3s ease;
			@include _1920{
				font-size: fluid_1280(24);
				line-height: fluid_1280(30);
			}
			@include _992{
				font-family: 'Golos';
				font-size: 14px;
				line-height: 125%;
			}
		}
		
		svg{
			fill: #fff;
			transition: all .3s ease;
			width: 30px;
			height: 30px;
			margin-bottom: -2px;
			fill: #000;
			@include _1920{
				width: fluid_1280(30);
				height: fluid_1280(30);
			}
			@include _992{
				width: 16px;
				height: 16px;
			}
		}
		&:hover{
			background-color: #000;

			span{
				color: #fff;
			}
			svg{
				fill: #fff;
			}
		}
	}
	&--link{
		font-family: 'Golos';
		font-size: 24px;
		line-height: 30px;
		color: #0F20E5;
		transition: all .3s ease;
		text-decoration: underline;
		@include _1920{
			font-size: fluid_1280(24);
			line-height: fluid_1280(30);
		}
		@include _992{
			font-family: 'Golos';
			font-size: 14px;
			line-height: 125%;
		}
		&:hover{
			opacity: .75;
		}
	}
	&--pink{
		border-radius: 12px;
		background-color: #FF1493;
		border: 1px solid transparent;
		transition: all .3s ease;
		@include _1920{
			border-radius: fluid_1280(12);
		}
		@include _992{
			border-radius: 5px;
			height: 34px;
		}
		span{
			font-family: 'Golos';
			font-size: 24px;
			line-height: 30px;
			color: #fff;
			transition: all .3s ease;
			@include _1920{
				font-size: fluid_1280(24);
				line-height: fluid_1280(30);
			}
			@include _992{
				font-size: 14px;
				line-height: 125%;
				margin-bottom: 2px;
			}
		}
		svg{
			width: 29px;
			height: 29px;
			fill: transparent;
			stroke: #fff;
			@include _1920{
				width: fluid_1280(29);
				height: fluid_1280(29);
			}
		}
		&:hover{
			border-color: #FF1493;
			background-color: transparent;
			span{
				color: #FF1493;
			}
			svg{
				stroke: #FF1493;
			}
		}
	}
	&--pink-reversed{
		border-radius: 12px;
		background-color: #fff;
		border: 1px solid #FF1493;
		transition: all .3s ease;
		&:hover{
			background-color: #FF1493;
			span{
				color: #fff;
			}
			svg{
				stroke: #fff;
			}
		}
		@include _1920{
			border-radius: fluid_1280(12);
		}
		@include _992{
			border-radius: 5px;
		}
		span{
			font-family: 'Golos';
			font-size: 24px;
			line-height: 125%;
			color: #FF1493;
			transition: all .3s ease;
			@include _1920{
				font-size: fluid_1280(24);
			}
			@include _992{
				font-size: 14px;
			}
		}
		svg{
			width: 29px;
			height: 29px;
			fill: transparent;
			stroke: #FF1493;
			margin-left: 7px;
			transition: all .3s ease;
			@include _1920{
				width: fluid_1280(29);
				height: fluid_1280(29);
				margin-left: fluid_1280(7);
			}
			@include _992{
				width: 16px;
				height: 16px;
				margin-left: 5px;
			}
		}
	}
	&--black-reversed{
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #000;
		border-radius: 11px;
		transition: all .3s ease;
		@include _1920{
			border-radius: fluid_1280(11);
		}
		@include _992{
			border-radius: 5px;
		}
		&:hover{
			background-color: #000;
			span{
				color: #fff;
			}
			svg{
				fill: #fff;
			}
		}
		span{
			font-family: 'Golos';
			font-weight: 400;
			font-size: 24px;
			line-height: 125%;
			transition: all .3s ease;
			@include _1920{
				font-size: fluid_1280(24);
			}
			@include _992{
				font-size: 14px;
			}
		}
		svg{
			fill: transparent;
			stroke: #000;
			width: 15px;
    		height: 16px;
			margin-left: 14px;
			@include _1920{
				width: fluid_1280(15);
				height: fluid_1280(16);
				margin-left: fluid_1280(14);
			}
			@include _992{
				width: 20px;
				height: 10px;
				margin-left: 11px;
			}
		}
	}
}

.d-flex-row{
	display: flex;
	flex-direction: row;
}


.js-open-other{
	&.opened{
		.js-hidden{
			display: block;
		}
	}
	.js-hidden{
		display: none;
	}
}


.open-other{
	cursor: pointer;
	margin: 20px 0 0;
	&:hover{
		span{
			border-color: transparent;
		}
	}
	span{
		font-size: 15px;
		line-height: 18px;
		border-bottom: 1px solid #999999;
		color: #999999;
		transition: border-color $tr-time;
	}
}

.lead-phone{
	svg{
		width: 15px;
		height: 15px;
		fill: $main-clr-hover;
		margin-right: 8px;
	}
	span{
		font-size: 15px;
		line-height: 18px;
		color: $text-clr;
	}
}

.breadcrumbs{
	padding: 19px 0 20px;
	@include _1920{
		padding: fluid_1280(19) fluid_1280(20);
	}
	@include _992{
		padding: 10px 0 15px;
	}
	&-wrapper{
		// padding: 0 23px;
		margin-bottom: 30px;
		@include _1920{
			// padding: 0 fluid_1280(23);
			margin-bottom: fluid_1280(30);
		}
	}
	&-item{
		font-family: 'PT Sans';
		font-weight: 400;
		font-size: 17.5465px;
		line-height: 125%;
		color: #000;
		margin-right: 2px;
		@include _1920{
			font-size: fluid_1280(17.5465);
		}
		@include _992{
			font-size: 11px;
		}
		&.last{
			color: #B6B6B6;
		}
		svg{
			fill: transparent;
			stroke: #B6B6B6;
			width: 9px;
			height: 9px;
			transform: rotate(-90deg);
			@include _1920{
				width: fluid_1280(9);
				height: fluid_1280(9);
			}
			@include _992{
				width: 6px;
				height: 6px;
			}
		}
	}
	&-back{
		display: flex;
		align-items: center;
		margin: 15px 0 0 23px;
		padding: 11px 38px 16px 38px;
		border-radius: 12px;
		width: fit-content;
		box-shadow: 1.46px 1.46px 8.77px 0px #00000029;
		@include _1920{
			margin: fluid_1280(15) 0 0 fluid_1280(23);
			padding: fluid_1280(11) fluid_1280(38) fluid_1280(16) fluid_1280(38);
			border-radius: fluid_1280(12);
		}
		@include _992{
			margin: 10px 0 0;
			padding: 5px 8px;
			border-radius: 5px;
		}
		&:hover{
			svg{
				fill: $main-clr-hover;
			}
			span{
				color: $main-clr-hover;
			}
		}
		svg{
			width: 29px;
			height: 29px;
			fill: #757575;
			margin-right: 7px;
			transition: fill $tr-time;
			@include _1920{
				width: fluid_1280(29);
				height: fluid_1280(29);
			}
			@include _992{
				width: 10px;
				height: 10px;
			}
		}
		span{
			font-family: 'PT Sans';
			font-size: 38px;
			line-height: 125%;
			color: #757575;
			transition: color $tr-time;
			@include _1920{
				font-size: fluid_1280(38);
			}
			@include _992{
				font-size: 11px;
			}
		}
	}
}

.page-title{
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	margin: 0 0 35px;
	&.category-title{
		margin: -74px 0 50px 26.1%;
		@include _1920{
			margin: fluid_1280(-74) 0 fluid_1280(50) 26.1%;
		}
		@include _992{
			margin: 0 0 16px;
		}
	}
	@include _1920{
		margin: 0 0 fluid_1280(35);
	}
	@include _992{
		margin: 0 0 25px;
	}
	&--line{
		align-items: center;
		justify-content: space-between;
	}
	&--notify{
		flex-direction: column;
	}
	&__name{
		margin-right: 15px;
		@include _1920{
			margin-right: fluid_1280(15);
		}
		@include _992{
			margin-right: 10px;
		}
		
		h1, span{
			font-family: 'Golos';
			font-weight: 600;
			font-size: 39px;
			line-height: 120%;
			color: $text-clr;
			@include _1920{
				font-size: fluid_1280(39);
			}
			@include _992{
				font-size: 18px;
			}
		}
	}
	&__link{
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		color: $text-clr;
		position: relative;
		transition: color $tr-time;
		&:hover{
			color: $main-clr-hover;
		}
		&::before{
			content: '';
			display: block;
			width: 6px;
			height: 6px;
			background-color: $main-clr-hover;
			position: absolute;
			border-radius: 100%;
			left: -15px;
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}
	&__content{
		display: flex;
		flex-direction: row;
		align-items: flex-end;
	}
	&__notify{
		margin: 15px 0 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		@include _768{
			flex-direction: column;
    		align-items: flex-start;
		}
		&-text{
			font-size: 15px;
			line-height: 24px;
			color: #282828;
		}
		&-link{
			font-weight: 700;
			font-size: 16px;
			line-height: 24px;
			text-decoration-line: underline;
			color: $main-clr-hover;			
		}
	}
}

.fake-checkbox{
	display: flex;
	align-items: center;
	cursor: pointer;
	width: fit-content;
	&:hover{
		div{
			p{
				color: #000;
			}
			span{
				border-color: #FF1493;
			}
		}
	}
	input{
		display: none;
		&:checked + div{
			span{
				border-color: #FF1493;
				p{
					background-color: #FF1493;
				}
			}
			p{
				color: #000;
			}
		}
	}
	div{
		display: flex;
		align-items: center;
	}
	span{
		border: 1px solid #818181;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 25px;
		aspect-ratio: 1/1;
		margin-right: 16px;
		@include _1920{
			margin-right: fluid_1280(16);
			width: fluid_1280(25);
		}
		@include _992{
			margin-right: 8px;
			width: 12px;
		}
		p{
			width: 13px;
			aspect-ratio: 1/1;
			border-radius: 50%;
			@include _1920{
				width: fluid_1280(13);
			}
			@include _992{
				width: 8px;
			}
		}
	}
	p{
		font-family: 'PT Sans';
		font-size: 21px;
		line-height: 140%;
		color: #565656;
		@include _1920{
			font-size: fluid_1280(21);
		}
		@include _992{
			font-size: 14px;
		}
	}

}
.swiper{
	transition: opacity $tr-time;
	opacity: 1;
	&-init{
		opacity: 0;
	}
	&-nav{
		cursor: pointer;
		&--next{
			svg{
				transform: rotate(180deg);
			}
		}
		svg{
			width: 36px;
			height: 25px;
			fill: #999999;
			transition: fill $tr-time;
		}
		&:hover{
			svg{
				fill: $alt-clr;
			}
		}
	}
	&-pagination{
		z-index: 1;
		&-bullet{
			margin: 0 10px;
			width: 10px;
			height: 10px;
			background-color: #6A6A6A;
			opacity: 1;
			transition: background-color $tr-time;
			&-active{
				background-color: $main-clr;
			}
			@include _576{
				width: 8px;
				height: 8px;
				margin: 0 3px;
			}
		}
	}
}
.swiper-button-disabled{
	opacity: .6;
	pointer-events: none;
}

.no-photo{
	background-image: url('../images/placeholder.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.theme-counter{
    cursor: pointer;
    display: grid;
    grid-template-columns: 52px 1fr 52px;
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
	border-radius: 11px;
	@include _1920{
		grid-template-columns: fluid_1280(52) 1fr fluid_1280(52);
		border-radius: fluid_1280(11);
	}
	@include _992{
		grid-template-columns: 40px 1fr 40px;
		border-radius: 5px;
	}
    &__minus,
    &__plus{
		user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
		position: relative;
        svg{
			width: 13px;
			height: 13px;
			fill: $text-clr;
			transition: fill $tr-time;
			@include _1920{
				width: fluid_1280(13);
				height: fluid_1280(13);
			}
			@include _992{
				width: 10px;
				height: 10px;
			}
		}
		&:hover{
			svg{
				fill: $main-clr-hover;
			}
		}
    }
	&__plus{
		&::after{
			content: '';
			display: block;
			position: absolute;
			left: 0px;
			top: 0px;
			width: 1px;
			height: calc(100% - 28px);
			margin: 14px 0;
			background-color: #AFAFAF;
		
			@include _1920{
				margin: fluid_1280(14) 0;
				height: fluid_1280(38);
			}
			@include _992{
				margin: 8px 0;
				height: 24px;
			}
		}
	}
	&__minus{
		&::after{
			content: '';
			display: block;
			position: absolute;
			right: 0px;
			top: 0px;
			width: 1px;
			height: calc(100% - 28px);
			margin: 14px 0;
			background-color: #AFAFAF;
		
			@include _1920{
				margin: fluid_1280(14) 0;
				height: fluid_1280(38);
			}
			@include _992{
				margin: 8px 0;
				height: 24px;
			}
		}
	}
    &__field{
        border: none;
        text-align: center;
		font-family: 'PT Sans';
		font-weight: 400;
		font-size: 20px;
		line-height: 125%;
		
        @include _1920{
            font-size: fluid_1280(20);
        }
		@include _992{
			font-size: 14px;
		}
    }
}

.swal2-title{
	@include _576{
		padding: 10px 20px 0;
		font-size: 22px;
	}
}

.swal2-html-container{
	@include _576{
		font-size: 16px;
	}
}

.swal2-actions{
	@include _576{
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 10px;
		margin: 0;
		padding: 10px 15px 0;
		.btn--swal{
			width: 100%;
			margin: 0;
		}
	}
}

.swal2-icon{
	@include _576{
		width: 50px;
		height: 50px;
		.swal2-icon-content{
			font-size: 30px;
		}
	}
}

.dropdown-1{
	display: flex;
	flex-direction: column;
	position: absolute;
	background-color: #fff;
	max-height: 0px;
	overflow: hidden;
	transition: all .3s ease;
	top: 100%;
	z-index: 3;
	box-shadow: 1.5px 1.5px 9px 0px #00000029;
	border-radius: 0 0 12px 12px;
	@include _1920{
		border-radius: 0 0 fluid_1280(12) fluid_1280(12);
	}
	@include _992{
		border-radius: 0 0 5px 5px;
	}

	&__item{
		width: max-content;
		padding: 20px;
		@include _1920{
			padding: fluid_1280(20);
		}
		@include _992{
			padding: 10px;
		}
		&__title{
			transition: all .3s ease;
			cursor: pointer;
			width: 100%;
			&:hover{
				color: #FF1493;
				span{
					color: #FF1493;
				}
			}
			span{
				font-size: 21px;
				line-height: 125%;
				color: #000;
				font-family: 'PT Sans';
				transition: all .3s ease;
				@include _1920{
					font-size: fluid_1280(21);
				}
				@include _992{
					font-size: 12px;
				}
			}
		}
	}
}

