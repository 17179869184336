.product-slider{
    padding: 50px 0;
    @include _1920{
        padding: fluid_1280(50) 0;
    }
    @include _768{
        padding: 20px 0;
    }
    &__container{
        // overflow: hidden;
        overflow-x: clip;
        padding: 3px 3px;
        @include _1920{
            padding: fluid_1280(3) fluid_1280(3);
        }
        .swiper{
            @include _992{
                margin: 0 calcFluid(60, 90, 320, 800);
            }
        }
    }
    &__nav{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        @include _1920{
            grid-column-gap: fluid_1280(0);
        }
    }
    &__title{
        margin-bottom: 27px;
        @include _1920{
            margin-bottom: fluid_1280(27);
        }
        @include _992{
            margin-bottom: 20px;
        }
        p{
            font-family: 'Golos';
            font-style: normal;
            font-weight: 600;
            font-size: 39px;
            line-height: 120%;
            @include _1920{
                font-size: fluid_1280(39);
            }
            @include _992{
                font-size: 21px;
            }
        }
    }
}