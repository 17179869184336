@import 'vendors/simple-scrollbar';

.container + .content-seo{
    margin: 70px 0 0;
    @include _1920{
        margin: fluid_1280(70) 0 0;
    }
    @include _992{
        margin: 40px 0;
    }
}

.content-seo{
    $this: &;
    padding: 70px 0;
    @include _1920{
        padding: fluid_1280(70) 0;
    }
    @include _992{
        padding: 30px 0;
    }
    &--bg{
        background: #F0F1F3;
    }
    &__wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 11px;
        overflow: hidden;
        @include _1200{
            grid-column-gap: 30px;
        }
        @include _768{
            grid-row-gap: 30px;
            grid-template-columns: 1fr;
        }
        &--reverse{
            #{$this}__image{
                order: 1;
            }
            #{$this}__text{
                order: 2;
            }
        }
    }
    &__image{
        max-height: 528px;
        width: 100%;
        border-radius: 15px;
        @include _1920{
            max-height: fluid_1280(528);
            border-radius: fluid_1280(15);
        }
        @include _768{
            max-height: 290px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__text{
        padding: 16px 20px 0 12px;
        @include _1920{
            padding: fluid_1280(16) fluid_1280(20) 0 fluid_1280(12);
        }
        > .content-text{
            height: 528px;
            overflow: scroll;
            &::-webkit-scrollbar {
            width: 2px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #FF1493; 
                border-radius: 20px;
                border: 3px solid #FF1493;
              }
            @include _1920{
                height: fluid_1280(528);
            }
            @include _992{
                height: 200px;
            }
        }

        .ss-content{
            padding-right: 45px;
            @include _1920{
                padding-right: fluid_1280(45);
            }
            @include _992{
                padding-right: 20px;
            }
        }

        .ss-wrapper{
            position: relative;
            &::before{
                content: '';
                display: block;
                width: 6px;
                height: 100%;
                background-color: #E3E3E3;
                position: absolute;
                top: 0;
                right: 0;
                @include _1920{
                    width: fluid_1280(6);
                }
            }
        }

        .ss-scroll{
            background: $main-clr;
            opacity: 1 !important;
            width: 6px !important;
            border-radius: 0px !important;
        }
    }
}
