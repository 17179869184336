.header{
    font-family: 'Golos';
    @include _992{
        position: sticky;
        top: 0;
        z-index: 25;
    }
    &-top{
        background-color: #000;
        &__wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 60px;
            @include _1920{
                height: fluid_1280(60);
            }
        }
        &__title{
            height: 100%;
            display: flex;
            align-items: center;
            color: #fff;
            font-size: 21px;
            // line-height: 26.25px;
            // padding-bottom: 5px;
            transition: opacity .3s ease;
            @include _1920{
                font-size: fluid_1280(21);
                // line-height: fluid_1280(26.25);
                // padding-bottom: fluid_1280(5);
            }
            &.dashed{
                border-bottom: 1px dashed #fff;
                cursor: pointer;
                &:hover{
                    border-color: transparent;
                }
            }
        }
        &__left{
            display: flex;
            height: 100%;
            margin-bottom: .3%;
            margin-left: .4%;
            &__item{
                display: flex;
                align-items: center;
                position: relative;
                height: 100%;
                margin-right: 60px;
                @include _1920{
                    margin-right: fluid_1280(60);
                }
                svg{    
                    width: 12px;
                    margin-left: 7px;
                    margin-bottom: 1px;
                    height: 7px;
                    fill: #000;
                    stroke: #fff;
                    transition: all .3s ease;
                    @include _1920{
                        width: fluid_1280(12);
                        margin-left: fluid_1280(7);
                        margin-bottom: fluid_1280(1);
                        height: fluid_1280(7);
                    }
                }
                &__dropdown{
                    position: absolute;
                    z-index: 1;
                    display: flex;
                    flex-direction: column;
                    background-color: #fff;
                    max-height: 0;
                    overflow: hidden;
                }
                &:hover{
                    .header-top__title{
                        opacity: .5;
                    }
                    svg{
                        opacity: .5;
                        transform: scaleY(-1);
                    }
                    .dropdown-1{
                        max-height: 60vh;
                    }
                }
            }
        }
        &__right{
            display: flex;
            align-items: center;
            &__item{
                padding: 3px 23.8px;
                border-left: 1px solid #fff;
                height: 2vw;
                display: flex;
                align-items: center;
                @include _1920{
                    padding: fluid_1280(3) fluid_1280(23.8);
                }
                &:last-child{
                    padding-right: 0;
                }
                &-phone{
                    font-weight: 500;
                    color: #fff;
                    font-size: 21px;
                    line-height: 26.25px;
                    margin-right: 5px;
                    transition: all .3s ease;
                    @include _1920{
                        font-size: fluid_1280(21);
                        line-height: fluid_1280(26.25);
                        margin-right: fluid_1280(5);
                    }
                    &:hover{
                        color: #FF1493;
                    }
                }
                &-work{
                    color: #CCC;
                    font-size: 21px;
                    line-height: 26.25px;
                    @include _1920{
                        font-size: fluid_1280(21);
                        // line-height: fluid_1280(26.25);
                    }
                }
            }
            &__vk{
                &:hover{
                    svg{
                        fill: #FF1493;
                    }
                }
                svg{
                    width: 30px;
                    height: 30px;
                    fill: #fff;
                    transition: all .3s ease;
                    @include _1920{
                        width: fluid_1280(30);
                        height: fluid_1280(30);
                    }
                }
            }
        }
    }
    .debug{
        //170
        margin-top: 0px;
        @include _1920{
            margin-top: fluid_1280(0);
        }
    }
    &-bottom{
        box-shadow: 0px 0px 24px 0px #00000029;
        background-color: #fff;
        // margin-top: 60px;
        @include _1920{
            //margin-top: fluid_1280(60);
            box-shadow: 0px 0px fluid_1280(24) 0px #00000029;
        }
        &__wrapper{
            display: flex;
            justify-content: space-between;
            height: 102px;
            align-items: center;
            @include _1920{
                height: fluid_1280(102);
            }
        }
        &__logo{
            margin-right: 37.5px;
            @include _1920{
                margin-right: fluid_1280(37.5);
            }
            img{
                width: 153px;
                height: 78px;
                object-fit: cover;
                @include _1920{
                    width: fluid_1280(153);
                    height: fluid_1280(78);
                }
            }
        }
        &__catalog-btn{
            margin-right: 37px;
            @include _1920{
                margin-right: fluid_1280(37);
            }
            .catalog-btn{
                display: flex;
                background-color: #000;
                align-items: center;
                padding: 12px 9px 12px 12px;
                border-radius: 12px;
                cursor: pointer;
                @include _1920{
                    padding: fluid_1280(12) fluid_1280(9) fluid_1280(12) fluid_1280(12);
                    border-radius: fluid_1280(12);
                }
                &:hover{
                    span{
                        &:nth-child(1){
                            transform: rotate(90deg);
                        }
                        &:nth-child(2){
                            transform: rotate(-90deg);
                        }
                        &:nth-child(3){
                            transform: rotate(-90deg);
                        }
                        &:nth-child(4){
                            transform: rotate(90deg);
                        }
                    }
                }
                &.opened{
                    svg{
                        transform: scaleY(-1);
                    }

                }
                &__icon{
                    display: grid;
                    grid-template-columns: 13.5px 13.5px;
                    grid-template-rows: 13.5px 13.5px;
                    column-gap: 3px;
                    row-gap: 3px;
                    @include _1920{
                        grid-template-columns: fluid_1280(13.5) fluid_1280(13.5);
                        grid-template-rows: fluid_1280(13.5) fluid_1280(13.5);
                        column-gap: fluid_1280(3);
                        row-gap: fluid_1280(3);
                    }
                    span{
                        border: 1.5px solid #FFFFFF;
                        border-radius: 3px;
                        transition: all .3s ease;
                        @include _1920{
                            border-radius: fluid_1280(3);
                        }
                    }
                }
                svg{
                    margin-left: 6px;
                    width: 9px;
                    height: 9px;
                    stroke: #fff;
                    transition: all .3s ease;
                    @include _1920{
                        margin-left: fluid_1280(6);
                        width: fluid_1280(9);
                        height: fluid_1280(9);
                    }
                }
            }
        }
        &__menu{
            display: flex;
            justify-content: space-between;
            height: 100%;
            margin-right: 95px;
            @include _1920{
                margin-right: fluid_1280(95);
            }
            &-item{
                $this: &;
                display: flex;
                align-items: center;
                height: 100%;
                cursor: pointer;
                position: relative;
                &:not(:last-child){
                    margin-right: 60px;
                    @include _1920{
                        margin-right: fluid_1280(60);
                    }
                }
                &__title{
                    font-size: 22.5px;
                    line-height: 28.13px;
                    color: #000;
                    margin-right: 7px;
                    @include _1920{
                        font-size: fluid_1280(22.5);
                        line-height: fluid_1280(28.13);
                        margin-right: fluid_1280(7);
                    }
                }
                svg{
                    width: 12px;
                    height: 8px;
                    fill: transparent;
                    stroke: #000;
                    transition: all .3s ease;
                    @include _1920{
                        width: fluid_1280(12);
                        height: fluid_1280(8);
                    }
                }
                &:hover{
                    #{$this}__title{
                        text-decoration: underline;
                    }
                    svg{
                        transform: scaleY(-1);
                    }
                    
                    .dropdown-1{
                        max-height: 60vh;
                    }
                }
            }
        }
        &__search{
            max-width: 851px;
            width: 100%;
            margin-right: 58px;
            margin-bottom: 3px;
            height: 63px;
            @include _1920{
                max-width: fluid_1280(851);
                margin-right: fluid_1280(58);
                margin-bottom: fluid_1280(3);
                height: fluid_1280(63);
            }
        }
        &__action-buttons{
            display: flex;
            justify-content: space-between;
            margin-bottom: 3px;
            @include _1920{
                margin-bottom: fluid_1280(3);
            }
            a{
                position: relative;
                cursor: pointer;
                &:hover{
                    .fill{
                        fill: #FF1493;
                    }
                    .stroke{
                        stroke: #FF1493;
                    }
                }
                &:not(:last-child){
                    margin-right: 44px;
                    @include _1920{
                        margin-right: fluid_1280(44);
                    }
                }
                span{
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    border-radius: 50%;
                    color: #fff;
                    font-family: 'Golos';
                    right: -8px;
                    top: -8px;
                    background-color: #E64569;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 125%;
                    &:empty{
                        display: none;
                    }
                    @include _1920{
                        width: fluid_1280(20);
                        height: fluid_1280(20);
                        right: fluid_1280(-8);
                        top: fluid_1280(-8);
                        font-size: fluid_1280(14);
                    }
                }
                svg{
                    width: 29px;
                    height: 29px;
                    transition: all .3s ease;
                    @include _1920{
                        width: fluid_1280(29);
                        height: fluid_1280(29);
                    }
                    &.fill{
                        fill: #000;
                        stroke: transparent;
                    }
                    &.stroke{
                        fill: transparent;
                        stroke: #000;
                    }
                }
            }
        }
        &-2{
            margin-top: 0px;
            height: 0px;
            box-shadow: none;
            transition: all .3s ease;
            overflow: hidden;
            .header-bottom__search{
                max-width: 750px;
                @include _1920{
                    max-width: fluid_1280(750);
                }
            }
        }
    }
    
    &-fixed{
        position: static;
        width: 100%;
        background-color: #fff;
        top: 0;
        max-height: fc1280(171);
        // overflow: hidden;
        transition: height .3s ease;
        z-index: 20;
        box-shadow: 0 0 24px 0 #00000029;
        @include _1920{
            box-shadow: 0 0 fluid_1280(24) 0 #00000029;
        }
        &.opened{
            .debug_work{
                display: none;
            }
            .header-bottom-2{
                height: 102px;
                overflow: visible;
                @include _1920{
                    height: fluid_1280(102);
                }
            }
        }
        &__debug{
            &-height{
                max-height: fc1280(271);
            }
        }
    }
    &__margin{
        margin-bottom: 60px;
        @include _1920{
            margin-bottom: fluid_1280(60);
        }
    }
    &__margin_debug{
        // 272
        margin-bottom: 0px!important;
        @include _1920{
            margin-bottom: fluid_1280(0)!important;
        }
    }

    .header-menu_debug{
        // top: 270px!important;
        // // top: 270px!important;
        // @include _1920{
        //     top: fluid_1280(270)!important;
        // }
    }
    .header-menu{
        display: flex;
        position: fixed;
        width: fit-content;
        top: 162px;
        z-index: 19;
        border-radius: 0 0 15px 15px;
        padding: 0 58px;
        overflow: hidden;
        max-height: 0px;
        transition: all .3s ease;
        @include _1920{
            top: fluid_1280(162);
            border-radius: 0 0 fluid_1280(15) fluid_1280(15);
            padding: 0 fluid_1280(58);
            overflow: hidden;
        }
        &.opened{
            max-height: 100vw;
        }
        &__wrapper{

            display: flex;
            flex-direction: column;
            padding: 6px 33px;
            margin-bottom: 20px;
            width: 580px;
            background-color: #fff;
            box-shadow: 0 0 24px 0 #00000029;
            border-radius: 0px 0px 15px 15px;
            z-index: 16;
            @include _1920{
                padding: fluid_1280(6) fluid_1280(33);
                width: fluid_1280(580);
                margin-bottom: fluid_1280(20);
                border-radius: 0px 0px fluid_1280(15) fluid_1280(15);
            }
        }
        &__item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 27px;
            @include _1920{
                padding: fluid_1280(24) fluid_1280(27);
            }
            &:hover{
                span{
                    color: #FF1493;
                }
            }
            span{
                font-family: 'Golos';
                font-weight: 500;
                font-size: 20px;
                line-height: 125%;
                transition: all .3s ease;
                @include _1920{
                    font-size: fluid_1280(24);
                }
            }
            svg{
                width: 12px;
                height: 12px;
                stroke: #000;
                fill: transparent;
                transform: rotate(-90deg);
                @include _1920{
                    width: fluid_1280(12);
                    height: fluid_1280(12);
                }
            }
            &:not(:last-child){
                border-bottom: 1px solid #D9D9D9;
            }
        }
        &__list{
            background-color: #fff;
            box-shadow: 1.5px 1.5px 9px rgba(0, 0, 0, 0.16);
            border-radius: 0 0 15px 15px;
            margin-left: 10px;
            padding: 50px 36px;
            width: 1205px;
            display: flex;
            justify-content: space-between;
            height: min-content;
            display: none;
            @include _1920{
                border-radius: 0 0 fluid_1280(15) fluid_1280(15);
                margin-left: fluid_1280(10);
                padding: fluid_1280(50) fluid_1280(36);
                width: fluid_1280(1205);
                box-shadow: fluid_1280(1.5) fluid_1280(1.5) fluid_1280(9) rgba(0, 0, 0, 0.16);
            }
            &.opened{
                display: flex;
                overflow: auto;
            }
            &__left{
                display: flex;
                flex-direction: column;
            }
            &__right{
                display: flex;
                height: 84%;
                img{
                    // height: 100%;
                    // width: 100%;
                    object-fit: contain;
                    height: 337px;
                    // object-fit: cover;
                    @include _1920{
                        height: fluid_1280(337);
                    }
                }
            }
            &__title{
                margin-left: 30px;
                margin-bottom: 14px;
                @include _1920{
                    margin-left: fluid_1280(30);
                    margin-bottom: fluid_1280(34);
                }
                span{
                    font-family: 'Golos';
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 120%;
                    @include _1920{
                        font-size: fluid_1280(30);
                    }
                }
            }
            &__item{
                padding: fc1280(5) fc1280(30);
                border-radius: 30px;
                border: 1px solid transparent;
                transition: all .3s ease;
                @include _1920{
                    border-radius: fluid_1280(30);
                }
                &:hover{
                    border-color: #FF1493;
                    span{
                        color: #FF1493;
                    }
                }
                &:not(:last-child){
                    margin-bottom: fc1280(5,0);
                }
                span{
                    font-family: 'Golos';
                    font-weight: 500;
                    font-size: 19px;
                    line-height: 125%;
                    border: 1px solid transparent;
                    transition: all .3s ease;
                    @include _1920{
                        font-size: fluid_1280(24);
                    }
                }
            }
        }
        &__menu-wrapper{
            box-shadow: 1.5px 1.5px 9px 0 #00000029;
            @include _1920{
                box-shadow: fluid_1280(1.5) fluid_1280(1.5) fluid_1280(9) 0 #00000029;
            }
        }
    }
    .toggle-on {
        animation-name: open;
        animation-duration: .4s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        animation-delay: 0s;
    }
    .toggle-off{
        animation-name: close;
        animation-duration: .4s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        animation-delay: 0s;
    }

    @keyframes open {
        0% {
            height: 0;
            overflow: hidden;
        }
        100% {
            // height: fc1280(271);
            height: fc1280(169);
            overflow: visible;
        }
    }
    @keyframes close {
        0% {
            // height: fc1280(271);
            height: fc1280(169);
            overflow: hidden;
        }
        100% {
            height: 0;
            overflow: hidden;
        }
    }

}
.debug_work{
    font-family: Golos;
    font-style: normal;
    background-image: url("/static/images/works.png");
    background-size: 100%;
    background-size: cover;
    &__container{
        padding: 18px 0px 23px;
        @include _1920{
            padding: fluid_1280(18) 0px fluid_1280(23);
        }
    }
    &__title{
        color: #000;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 8px;
        @include _1920{
            font-size: fluid_1280(30);
            margin-bottom: fluid_1280(8);
            @include _992{
                margin-bottom: 5px;
                font-size: 15px;
            }
        }
    }
    &__text{
        color: #000;
        font-size: fluid_1280(20);
        font-weight: 500;
        @include _992{
            font-size: 10px;
        }
    }

}
