.product-card{
    $this: &;
    position: relative;

    &[data-state="wishlist"] {
        #{$this}__actions-extra{
            flex-direction: row-reverse;
        }
    }
    &__wrapper{
        box-shadow: 0px 1.5px 3px 1.5px #5E5E5E26;
        border-radius: 15px;
        position: relative;
        z-index: 2;
        background-color: #fff;
        @include _1920{
            border-radius: fluid_1280(15);
        }
        @include _992{
            border-radius: 5px;
            box-shadow: 0px 2.59354px 12.9677px 1.29677px rgba(94, 94, 94, 0.15);
        }
    }
    &__actions-container{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        button{
            width: 100%!important;
            svg{
                fill: transparent;
                stroke: #7B7B7B;
            }
            &:first-child{
                border-right: 1px solid #DDDDDD;
            }
        }
    }
    &__main{
        display: block;
        &:hover{
            .product-card__name{
                color: #ff1493;
            }
        }
    }
    &__thumb{
        display: block;
        height: 287px;
        width: 287px;
        margin: 0 auto;
        padding-top: 27px;
        @include _1920{
            border-radius: fluid_1280(15);
            height: fluid_1280(287);
            width: fluid_1280(287);
            padding-top: fluid_1280(27);
        }
        @include _992{
            border-radius: 5px;
            height: 100px;
            width: 100px;
            padding-top: 11px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 12px;
            &.cover{
                object-fit: cover;
            }
            @include _1920{
                border-radius: fluid_1280(12);
            }
            @include _992{
                border-radius: 5px;
            }
        }
    }
    &__labels{
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        &-item{
            display: inline-block;
            padding: 5px 15px;
            font-size: 14px;
            line-height: 20px;
            color: #FFFFFF;
            border-radius: 140px;
            &--sale{
                background-color: #E55301;
            }
            &--new{
                background-color: #3FAFE3;
            }
            &--hit{
                background-color: #9B6BE8;
            }
            &:not(:last-child){
                margin: 0 0 5px;
                @include _576{
                    margin: 0 0 3px;
                }
            }
            @include _576{
                font-size: 12px;
                line-height: 18px;
                padding: 3px 8px;
            }
        }
    }
    &__content{
        display: block;
        padding: 23px 27px 0;
        @include _1920{
            padding: fluid_1280(23) fluid_1280(27) 0;
        }
        @include _992{
            padding: 6px 9px 0;
        }
    }
    &__sale{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #E64569;
        height: 41px;
        width: 75px;
        top: 0px;
        left: 0px;
        border-radius: 15px 0;
        color: #fff;
        font-size: 21px;
        line-height: 26.25px;
        font-family: 'Golos';
        @include _1920{
            height: fluid_1280(41);
            width: fluid_1280(75);
            border-radius: fluid_1280(15) 0;
            font-size: fluid_1280(21);
            line-height: fluid_1280(26.25);
        }
        @include _992{
            border-radius: 5px 0;
            font-size: 12px;
            line-height: 125%;
            height: 15px;
            width: 35px;
        }
    }
    &__price{
        display: flex;
        justify-content: center;
        
        margin: 16px 0 0;
        padding: 19px 0 15px;
        border-top: 1px solid #DDDDDD;
        border-bottom: 1px solid #DDDDDD;
        @include _1920{
            margin: fluid_1280(49) 0 0;
            padding: fluid_1280(19) 0 fluid_1280(15);
        }
        @include _992{
            margin: 7px 0 0;
            padding: 3px 0 4px;
        }
        &--current{
            font-family: 'Golos';
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
            color: #000;
            @include _1920{
                font-size: fluid_1280(24);
                line-height: fluid_1280(30);
            }
            @include _992{
                font-size: 14px;
                line-height: 125%;
            }
        }
        &--old{
            position: relative;
            margin-left: 15px;
            margin-right: 15px;
            font-family: 'Golos';
            font-size: 20px;
            line-height: 25px;
            color: #8A8A8A;
            text-decoration: line-through;
            text-decoration-color: #E64569;
            @include _1920{
                margin-left: fluid_1280(15);
                margin-right: fluid_1280(15);
                font-size: fluid_1280(20);
                line-height: fluid_1280(25);
            }
            @include _992{
                margin-left: 5px;
                font-size: 12px;
                line-height: 125%;
            }
        }
    }
    &__name{
        font-family: 'PT Sans';
        font-size: 20px;
        line-height: 25px;
        color: #000;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 100px;
        transition: all .3s ease;
        @include _1920{
            font-size: fluid_1280(20);
            line-height: fluid_1280(25);
            height: fluid_1280(100);
        }
        @include _992{
            font-size: 14px;
            line-height: 125%;
            min-height: 120px;
            display: block;
            padding: 0 6px;

        }
    }
    &__actions{
        justify-content: space-between;
        padding: 16px 15px 21px;
        @include _1920{
            padding: fluid_1280(16) fluid_1280(15) fluid_1280(21);
        }
        @include _992{
            padding: 5px 0;
        }
        &-cart{
            width: 100%;
            height: 45px;
            @include _1920{
                height: fluid_1280(45);
            }
        }
        &-extra{
            .btn{
                height: 45px;
                width: 45px;
                @include _1920{
                    height: fluid_1280(45);
                    width: fluid_1280(45);
                }
                @include _992{
                    height: 24px;
                }
                &[data-state='added']{
                    svg{
                        // stroke: #000;
                        stroke: none;
                        fill: #F15980;
                        &:hover{
                            stroke: #7B7B7B;
                        }
                    }
                }
            }
        }
    }
    &__hidden{
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px 20px 20px;
        opacity: 0;
        transition: opacity $tr-time;
        .btn{
            width: 100%;
            height: 45px;
        }

        @include _576{
            position: static;
            opacity: 1;
            margin: 0;
            padding: 15px;
        }
    }

    &:hover{
        z-index: 9;
        #{$this}__inner{
            height: calc(100% + 55px);
            @include _576{
                height: 100%;
            }
        }
        #{$this}__hidden{
            opacity: 1;
        }
    }
    &__wish-close{
        position: absolute;
        right: -10px;
        top: -10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        box-shadow: 1.46px 1.46px 8.77px rgba(0, 0, 0, 0.16);
        border-radius: 50%;
        border: none;
        width: 35px;
        height: 35px;
        cursor: pointer;
        transition: all .3s ease;
        &:hover{
            background-color: #161616;
            svg{
                stroke: #fff;
            }
        }
        svg{
            width: 14px;
            height: 14px;
            stroke: #909090;
            transition: all .3s ease;
        }
    }
}